import React from "react";

function TableGoodsItem(props) {
  return (
    <ul className="table-goods__form-columns" key={props.id}>
      <li className="table-goods__form-column table-goods__form-column_type_label">
        <label htmlFor={props.id} className="table-goods__form-label">{props.name}</label>
      </li>
      <li className="table-goods__form-column">
        <p className="table-goods__form-quantity">{props.count}</p>
      </li>
      <li className="table-goods__form-column">
        <input type="text" className="table-goods__form-input" id={props.id} disabled />
      </li>
    </ul>
  )
}

export default TableGoodsItem;