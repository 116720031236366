import React, { useState, useEffect } from "react";
import './TableGoods.css';
import TableGoodsItem from "../TableGoodsItem/TableGoodsItem";

function TableGoods(props) {
  const [goods, setGoods] = useState([]);
  const [firstArr, setFirstArr] = useState([]);
  const [secondArr, setSecondArr] = useState([]);
  const [thirdArr, setThirdArr] = useState([]);
  const [isClickButtonMeshera, setIsClickButtonMeshera] = useState(false);
  const [isClickButtonGorkogo, setIsClickButtonGorkogo] = useState(false);

  function handleClickButtonMeshera(evt) {
    evt.preventDefault();
    if (!isClickButtonMeshera) {
      setIsClickButtonMeshera(true);
      setIsClickButtonGorkogo(false);
      setGoods(props.goodsMeshera);
    } else {
      setIsClickButtonMeshera(false);
      setGoods([]);
      setFirstArr([]);
      setSecondArr([]);
    }
  }

  function handleClickButtonGorkogo(evt) {
    evt.preventDefault();
    if (!isClickButtonGorkogo) {
      setIsClickButtonGorkogo(true);
      setIsClickButtonMeshera(false);
      setGoods(props.goodsGorkogo);
    } else {
      setIsClickButtonGorkogo(false);
      setGoods([]);
      setFirstArr([]);
      setSecondArr([]);
    }
  }

  useEffect(() => {
    if (goods.length > 40 && goods.length < 80) {
      setFirstArr(goods.slice(0, 40));
      setSecondArr(goods.slice(40, 80));
    } else if (goods.length > 80) {
      setFirstArr(goods.slice(0, 40));
      setSecondArr(goods.slice(40, 80));
      setThirdArr(goods.slice(80));
    }
  }, [goods]);

  return (
    <div className="table-goods">

      <form className="table-goods__form">
        <div className="table-goods__form-buttons">
          <button
            className={`table-goods__submit-button ${isClickButtonMeshera ? 'table-goods__submit-button_type_active' : ''}`}
            onClick={handleClickButtonMeshera}
          >
            Распечатать Мещера
          </button>
          <button
            className={`table-goods__submit-button ${isClickButtonGorkogo ? 'table-goods__submit-button_type_active' : ''}`}
            onClick={handleClickButtonGorkogo}
          >
            Распечатать Горького
          </button>
        </div>
        <div className={`table-goods__form-content ${isClickButtonGorkogo || isClickButtonMeshera ? 'table-goods__form-content_type_active' : ''
          }`}>
          <div className="table-goods__form-content-column">
            <ul className="table-goods__form-columns table-goods__form-columns_type_name">
              <li className="table-goods__form-column table-goods__form-column_type_name table-goods__form-column_type_label">Наименование</li>
              <li className="table-goods__form-column table-goods__form-column_type_name">Склад</li>
              <li className="table-goods__form-column table-goods__form-column_type_name">Факт</li>
            </ul>
            {
              firstArr.map(item => (
                <TableGoodsItem
                  key={item.id}
                  name={item.name}
                  count={item.count}
                  id={item.id}
                />
              ))
            }
          </div>
          <div className="table-goods__form-content-column">
            {goods.length > 40 && <ul className="table-goods__form-columns table-goods__form-columns_type_name">
              <li className="table-goods__form-column table-goods__form-column_type_name table-goods__form-column_type_label">Наименование</li>
              <li className="table-goods__form-column table-goods__form-column_type_name">Склад</li>
              <li className="table-goods__form-column table-goods__form-column_type_name">Факт</li>
            </ul>
            }
            {
              goods.length > 40 && secondArr.map(item => (
                <TableGoodsItem
                  key={item.id}
                  name={item.name}
                  count={item.count}
                  id={item.id}
                />
              ))
            }
          </div>
          <div className="table-goods__form-content-column">
            {goods.length > 80 && <ul className="table-goods__form-columns table-goods__form-columns_type_name">
              <li className="table-goods__form-column table-goods__form-column_type_name table-goods__form-column_type_label">Наименование</li>
              <li className="table-goods__form-column table-goods__form-column_type_name">Склад</li>
              <li className="table-goods__form-column table-goods__form-column_type_name">Факт</li>
            </ul>
            }
            {
              goods.length > 80 && thirdArr.map(item => (
                <TableGoodsItem
                  key={item.id}
                  name={item.name}
                  count={item.count}
                  id={item.id}
                />
              ))
            }
          </div>
        </div>

      </form>

    </div>
  )
}

export default TableGoods;