export default class Api {
  constructor(options) {
    this._baseUrl = options.baseUrl;
  }

  _getCheck(res) {
    if (res.ok) {
      return res.json();
    } else {
      return Promise.reject(`Ошибка ${res.status}`);
    }
  }

  getGoodsMeshera() {
    return fetch(this._baseUrl + '/?club_id=3', {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(res => this._getCheck(res));
  }

  getGoodsGorkogo() {
    return fetch(this._baseUrl + '/?club_id=1', {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(res => this._getCheck(res));
  }
}

export const api = new Api({
  baseUrl: 'https://n8n.ezgaming.gg/webhook/get-warehouse',
});
