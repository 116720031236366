import React, { useState } from 'react';
import './App.css';
import TableGoods from '../TableGoods/TableGoods';
import { api } from '../../utils/Api';
function App() {
  const [goodsMeshera, setGoodsMeshera] = useState({});
  const [goodsGorkogo, setGoodsGorkogo] = useState({});

  React.useEffect(() => {
    Promise.all([api.getGoodsGorkogo(), api.getGoodsMeshera()])
      .then(([goodsG, goodsM]) => {
        setGoodsGorkogo(goodsG.data.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0
        }).filter(item => {
          return !item.name.startsWith('@')
        }));
        setGoodsMeshera(goodsM.data.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0
        }).filter(item => {
          return !item.name.startsWith('@')
        }));
      })
      .catch(err => console.log(err));
  }, [])

  return (
    <div className="app">
      <TableGoods
        goodsMeshera={goodsMeshera}
        goodsGorkogo={goodsGorkogo}
      />
    </div>
  );
}

export default App;
